import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InviteService } from '../../services/invite.service';
import * as _ from 'lodash';
import EmailValidation from "emailvalid";
import * as phone from 'phone';
import { isAndroid, isIOS } from 'mobile-device-detect';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  public isLoading: boolean = true;
  public isError: string = null;
  private inviteData: any;
  private inviteID: string;

  public logo: string = null;

  public register: any = {
    inviteID: null,
    orgID: null,
    role: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    loading: false,
    error: null,
    complete: false,
    password: null
  }

  constructor(private route: ActivatedRoute, private inviteService: InviteService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.inviteID = params.get("id");
      this.register.inviteID = this.inviteID;
      this.loadInviteData(params.get("id"))
    })
  }

  async loadInviteData(id: string){
    this.inviteData = await this.inviteService.loadInviteData(id)
    if(!this.inviteData.error){
      this.logo = this.inviteData.data.platform.platformLogo;
      if(this.inviteData.data.used){
        this.register.complete = true;;
      }else{
        this.resetRegister();
        this.register.email = this.inviteData.data.emailAddress.toLowerCase();
        this.register.orgID = this.inviteData.data.orgID;
        this.register.role = this.inviteData.data.role;
      }
      this.isLoading = false;
    }else{
      this.isError = this.inviteData.error;
      this.isLoading = false;
    }
  }

  resetRegister(){
    this.register.firstName = "";
    this.register.lastName = "";
    this.register.email = "";
    this.register.phone = "";
  }

  needsPassword(): boolean {
    let need = false;
    if(this.inviteData.data.role == "PLATFORM_ADMIN"){ need = true; }
    if(this.inviteData.data.role == "PLATFORM_MANAGER"){ need = true; }
    if(this.inviteData.data.role == "ORG_ADMIN"){ need = true; }
    if(this.inviteData.data.role == "ORG_MANAGER"){ need = true; }
    return need;
  }

  async submitRegister(){
    this.register.error = null;
    
    if(!this.register.firstName || this.register.firstName == ""){
      this.register.error = "First Name cannot be blank";
      return false;
    }
    if(!this.register.lastName || this.register.lastName == ""){
      this.register.error = "Last Name cannot be blank";
      return false;
    }
    if(!this.register.phone || this.register.phone == ""){
      this.register.error = "Phone cannot be blank";
      return false;
    }
    if(!this.register.email || this.register.email == ""){
      this.register.error = "Email cannot be blank";
      return false;
    }
    const ev = new EmailValidation({ allowFreemail: true })
    const emailCheck = ev.check(this.register.email);
    if(!emailCheck.valid){
      this.register.error = "Invalid Email Address"
      return false;
    }
    if(phone(this.register.phone).length == 0){
      this.register.error = "Invalid Mobile Phone number";
      return false;
    }
    if(this.needsPassword()){
      if(!this.register.password || this.register.password == ""){
        this.register.error = "Password cannot be blank";
        return false;
      }
      if(this.register.password.length < 8){
        this.register.error = "Password must be 8 characters in length.";
        return false;
      }
    }
    this.register.loading = true;

    var params: any = {
      orgID: this.register.orgID,
      role: this.register.role,
      inviteID: this.inviteID,
      firstName: this.register.firstName,
      lastName: this.register.lastName,
      email: emailCheck.email,
      phone: phone(this.register.phone)[0],
      password: this.register.password.trim()
    };
    const inviteSubmit: any = await this.inviteService.saveInviteData(params);
    this.register.loading = false;
    if(inviteSubmit.error){
      this.register.error = inviteSubmit.error;
      return false;
    }
    this.register.complete = true;
  }

  public gotoApp(type: string) {
    if(type == 'android'){
      if(this.inviteData.data.platform.app_android){
        window.open(this.inviteData.data.platform.app_android, "_system")
      }
    }
    if(type == 'ios'){
      if(this.inviteData.data.platform.app_ios){
        window.open(this.inviteData.data.platform.app_ios, "_system")
      }
    }
  }

}
